/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function getNavbarHeight() {
          var w = window.innerWidth;
          if( w < 768 ) {
            return 180;
          } else {
            return 200;
          }
        }

        // Hamburger Menu
        function toggleNavBar() {
          $('.navbar-menu').fadeToggle(400);
        }
        $('#menu-toggle').click( toggleNavBar );
        $('#menu-toggle').click(function(){
          $('body').toggleClass('no-scroll');
        });

        $("#menu-toggle").click(function() {
          var icon = $(this).find(".ico");
          if (icon.hasClass("ico-menu") ) {
            icon.removeClass("ico-menu");
            icon.addClass("ico-cross");
          } else {
            icon.removeClass("ico-cross");
            icon.addClass("ico-menu");
          }
        });


        // Form Select
        $("select").each(function() {
          $(this).wrapAll("<div class='select-wrapper'></div>");
        });

        // Smooth Scrolling
        function scrollToSection(e) {
          // e.preventDefault();
          var target = $(this).attr("href");
          if( target.indexOf('#') === 0 ) {
            var element = $(target);
            $(window).scrollTo(target, {duration: 333, offset: -( getNavbarHeight() ) });
          }
        }
        $('.scroller').click( scrollToSection );


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // http://darsa.in/sly/
        // setup featured companies slider
        // jQuery.extend(Sly.defaults, {
        //   speed: 300,
        //   smart: 1,
        //   forward: '.btn.forward',
        //   backward: '.btn.backward',
        // });
        // var sly = new Sly('#sly-frame');

        // This sets the header's top position to 0 after we scroll
        // passed the memorial banner for the founder
        $(document).on('scroll', function(e) {
          var scrollTop     = $(window).scrollTop(),
              elementOffset = $('.founder-memorial-header').offset().top,
              distanceFromTop      = (elementOffset - scrollTop);

          distanceFromTop = Math.abs(distanceFromTop);

          if(distanceFromTop <= 100) {
            $('header.banner').css({top: 100-distanceFromTop});
          } else {
            $('header.banner').css({top: 0});
          }
        });

        $('.featured-companies-list').slick({
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                infinite: true,

              }

            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 1,
                infinite: true
              }

            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                infinite: true
              }

            }
          ]

        });

        var CarouselIntervalId = null;
        var CarouselTimeoutId = null;
        var CarouselIntervalSeconds = 2;
        // after manual click on carousel, pause this long
        var CarouselRestartSeconds = 2.5;

        function setActiveCarouselSlide(item_id) {
          // hide the current carouselItem by removing the active class
          // also remove unique background color from carousel button
          $('.carousel-item.active').fadeOut(400).toggleClass('active');
          $('.carousel-button.active').toggleClass('active');


          // add active class to new carousel item and button
          $('.' + item_id).fadeIn(400).toggleClass('active');
          $('#' + item_id).toggleClass('active');
        }

        // called to advance slides from setInterval
        function advanceCarouselIntervalFn() {
          var $activeEl = $('.carousel-button.active');
          var numSlides = $(".carousel-controller > span").length;
          var idBase = 'carousel-item-';

          if ($('.carousel-button.active').length > 0) {
            var id = $('.carousel-button.active').attr('id');
            var matches = id.match(/^[^\d]+(\d+)/);
            var slideNum = null;
            if (matches && matches.length === 2) {
              slideNum = parseInt(matches[1], 10);
              slideNum = (slideNum >= numSlides) ? 1 : ++slideNum;
              setActiveCarouselSlide(idBase + String(slideNum));
            }
          } else {
            clearInterval(CarouselIntervalId);
            CarouselIntervalId = null;
            console.warn('Couldn\'t find active carousel item.');
          }
        }

        // start timer to advance slides
        function startCarouselTimer() {
          CarouselIntervalId = setInterval(advanceCarouselIntervalFn, CarouselIntervalSeconds * 1000);
        }

        function pauseCarouselTimer() {
          if (CarouselTimeoutId) {
            clearTimeout(CarouselTimeoutId);
            CarouselTimeoutId = null;
          }

          if (CarouselIntervalId) {
            clearInterval(CarouselIntervalId);
            CarouselIntervalId = null;
            CarouselTimeoutId = setTimeout(startCarouselTimer, CarouselRestartSeconds * 1000);
            console.log('Pausing carousel for ' + CarouselRestartSeconds + ' seconds.', new Date());
          }
        }

        // carousel controller click handler
        $('.carousel-button').on('click', function(e) {
          // get ID from button (it's also a unique identifier
          // for carousel items)
          var carouselItemId = $(this).attr('id');
          setActiveCarouselSlide(carouselItemId);
          pauseCarouselTimer();
        });

        // kick off timer for carousel slide advance
        startCarouselTimer();


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Portfolio page
    'portfolio': {
      init: function() {
        // JavaScript to be fired on the Portfolio page

        $('.portfolio-featured-companies').slick({
          infinite: true,
          dots: true
        });
        /**
         * Currently active company nav, `active` or `exitec`
         * @global
         * @type {String}
         */
        var SelectedCategory = "active";

        /**
         * Display either `active` or `exited` companies.
         *
         * @param  {string}  one of `active` or `exited`
         * @return {void}
         */
        function selectGroup(group_name) {
          $("#portfolio-nav a").removeClass('active');
          if (group_name === 'active') {
            $("#portfolio-nav a[data-cat=active]").addClass('active');
            // show active companies
            $("#exited-companies").hide();
            $("#active-companies").show();
          } else {
            // show exited companies
            $("#portfolio-nav a[data-cat=exited]").addClass('active');
            $("#active-companies").hide();
            $("#exited-companies").show();
          }
          // @global
          SelectedCategory = group_name;
        }


        /**
         * Mouseenter event handler for featured companies
         * @param  {jQuery.Event}
         * @return {void}
         */
        function handlerIn(evt) {
          var $this = $(this);
          $this.addClass('flipped');
        }

        /**
         * Mouseleave event handler for featured companies
         * @param  {jQuery.Event}
         * @return {void}
         */
        function handlerOut(evt) {
          var $this = $(this);
          $this.removeClass('flipped');
        }

        /**
         * Handle clicks on Active / Exited company anchors.
         * Shows/hides appropriate set of cos.
         *
         * @param  {jQuery.Event}
         * @return {void}
         */
        function portNavClickHandler(evt) {
          var $this = $(this);
          var cat = $this.data('cat');
          if ($this.hasClass('active')) {
            return;
          }
          selectGroup(cat);
        }

        /**
         * @param  {JQuery.Event}
         * @return {void} opens new tab with company website url
         */
        function companyClickHandler(evt) {
          evt.preventDefault();
          var $coEl = $(this).parent('.company');
          if ($coEl.hasClass('open')) {
            $coEl.removeClass('open');
          } else {
            $coEl.addClass('open');
          }
        }

        // this must be before code section that follows it, or close button won't work.
        $('.close-tab').click(function() {
          $('#featured-companies').css('background-image', 'url(/app/themes/archventure.com/assets/images/3d-orange.svg)');
          $('h5').show();
          // clear the url hash
          location.hash = "";
        });

        // check for direct link into a featured company.
        // Look at #hash and trigger the company to open.
        if (location.hash !== "") {
            /*
          var hash = location.hash;
          var selector = '.featured-block[data-hash="'  + hash.replace('#', '') + '"]';
          var $matchedCo = $(selector);
          if ($matchedCo.length === 1) {
            // need a slight delay before triggering click
            setTimeout(function () {
              $(selector).trigger('click');
            }, 150);

          } else {
            console.log('No match for featured-co (unexpected)', selector);
          }
          */
        }

        /**
         * Click handler for featured companies
         *
         * @param  {jQuery.Event}
         * @return {void} opens link in new tab
         */
        function featuredCoClickHandler(evt) {
          evt.preventDefault();
          var $this = $(this);
          var $url = $this.data('url');
          var $targetContainer = $("#expanded-view-container");
          // clone the expanded company element...
          var $el = $this.find(".featured-expanded").clone(true);
          // set company url on container (it will be
          // used to determine where in the list of featured
          // companies the user is)
          $targetContainer.data('url', $url);
          // append to our container
          $targetContainer.append($el);
          // show it
          $targetContainer.children().show();
          // hide the blocks
          $(".featured-block").hide();
          $('html, body').animate({
            scrollTop: $("#anchor").offset().top - 100
          }, 333);
          // window.open($url, 'co');
        }

        function showFeaturedCo(nextCo) {
          var $url = nextCo.data('url');
          var hash = nextCo.data('hash');
          location.hash = hash;
          var $targetContainer = $("#expanded-view-container");
          var $el = nextCo.clone(true);
          $targetContainer.data('url', $url);
          $targetContainer.html($el.find('.featured-expanded'));
          $targetContainer.children().show();
          $(".featured-block").hide();
        }

        function closeFeaturedCoClickHandler(evt) {
          evt.preventDefault();
          $("#expanded-view-container").empty();
          $(".featured-block").show();
        }

        //
        // Setup event handlers...
        //
        //$(".featured-block").hover(handlerIn, handlerOut);
        $("#company-list").on('click', '.co-title,.co-switch', companyClickHandler);
        //$("#featured-companies").on('click', '.close-tab', closeFeaturedCoClickHandler);
        //$("#featured-companies").on('click', '.featured-block', featuredCoClickHandler);

        $('.company-controller-button').on('click', function(e){
          e.preventDefault();

          var container = $(this).closest('#expanded-view-container');
          var companyUrl = container.data('url');
          var featuredBlock = $(".featured-block[data-url='" + companyUrl +"']");

          if($(this).hasClass('company-prev')) {
            showFeaturedCo(featuredBlock.prev());
          } else {
            if (companyUrl === $('.featured-block').last().data('url')) {
              showFeaturedCo($('.featured-block').first());
            } else {
              showFeaturedCo(featuredBlock.next());
            }
          }
        });

        $('.read-more').on('click', function(){
          var $featuredBlock = $(this).prev();
          if ($featuredBlock.hasClass('collapsed')) {
            $featuredBlock.removeClass('collapsed');
            $(this).html('<span>Read Less</span><i class="ico ico-chevron-up"></i>');
          } else {
            $featuredBlock.addClass('collapsed');
            $(this).html('<span>Read More</span><i class="ico ico-chevron-down"></i>');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the Portfolio page, after the init JS

        /**
         * Separate list of companies into columns
         *
         * @param containerId, ID of parent element that holds the list of companies
         */
        function wrapCompaniesInDiv(containerId) {

          // get number of companies
          var companiesLength = $('#' + containerId + ' .company').length;

          // calculate number of companies per column
          var companiesPerColumn = Math.ceil(companiesLength / 3);

          // number of columns
          var numberOfColumns = 3;

          // for every column, wrap companies in div
          for(var i = 0; i < numberOfColumns; i++) {

            // group compaines in div
            $('#' + containerId + '> .company').slice(0, companiesPerColumn).wrapAll('<div />');
          }
        }

        wrapCompaniesInDiv('active-exited-companies');

        /**
         * check for hash (#) and route appropriately.
         * Runs on page load.
         */
        if (location.hash !== "") {
          var h = location.hash.toLowerCase().replace('#', '');
          // #active is default so nothing to do there.
          var slideNumber = 0;
          $('.slick-list [id^=slick-slide] .featured-block').each(function(index){
            var hash = $(this).data('hash');
            if(hash === h){
              slideNumber = index;
              return;
            }
          });
          $('html, body').animate({
            scrollTop: $("#featured-companies").offset().top
          }, 2000);
          $('.portfolio-featured-companies').slick('slickGoTo', slideNumber);
        }
      }
    },

    // Team page
    'team': {
      init: function() {
        // JavaScript to be fired on the Team page

        var NavActiveClass = 'active';

        function _personClickHandler(evt) {
          var $this = $(this);
          var link;
          evt.preventDefault();
          link = $this.data('link');
          window.location.href = link;
        }

        //
        // Setup event handlers...
        //
        $("#team-view .person").on("click", _personClickHandler);

        // check for hash and route appropriately
      },
      finalize: function() {
        // JavaScript to be fired on the Team page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_post': {
      init: function() {
      },
      finalize: function() {
        $(".orange").on('click', function() {
          $(".at-expanding-share-button-toggle-bg").click();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
